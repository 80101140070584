import React, {Component} from 'react';
import { ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import './CheckoutForm.css';
import Spinner from 'react-bootstrap/Spinner';

class CheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            isSubmitting: false            
        };
        
        this.handleSubmit = this.handleSubmit.bind(this);
    }    

    handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        this.setState({ isSubmitting: true });

        const { stripe, elements } = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        //TODO! find a way to get the last4digit
        //const cardNumber = await elements.getElement('cardNumber');
        //console.log(cardNumber); //console.log(stripe); 
        //return false;

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: "https://activities.kenegie-manor.co.uk/payment-success",
            }
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.

        if (result.error.type === "card_error" || result.error.type === "validation_error") {
            this.setState({
                errorMessage: result.error.message,
                isSubmitting: false
            })
        } else {
            this.setState({
                errorMessage: "An unexpected error occured.",
                isSubmitting: false
            });
        }
      
    };
    
    render() {

        return (
            <form onSubmit={this.handleSubmit}>
                <h3 className="d-flex justify-content-between align-items-center mb-3">
                    <span className="text-muted">Your Payment</span>
                </h3>

                <PaymentElement />
                                               
                <button className="btn btn-primary btn-block mt-2"
                    disabled={!this.props.stripe} >                
                    Make Payment
                </button>

                {this.state.isSubmitting &&
                    <div className="d-block text-center mt-2">
                        <Spinner animation="border" />
                    </div>
                }

                {this.state.errorMessage &&
                    <div className="alert alert-danger d-block text-center mt-3">
                        <span>{this.state.errorMessage}</span>
                    </div>
                }
              
            </form>
        );
        
    }


}


export default function InjectedCheckoutForm() {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CheckoutForm stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    );
}