import React, {Component} from 'react';
import { loadStripe } from "@stripe/stripe-js";
import Spinner from 'react-bootstrap/Spinner';
import Moment from 'react-moment';
import 'moment-timezone';

/*
 * This is also the SAVE BOOKING page
 **/
export class PaymentSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorMessage: null,
            paymentIntent: null,
            isSaving: false,
            activityBooking: null,
            appliedVoucher: null
        };        
    }
        
    componentDidMount() {

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (clientSecret) {
            this.checkStatus(clientSecret);
        } else {
            this.saveBooking();
        }

    }
  
    renderGrid() {
        
        return (
            <div>                

                {this.state.errorMessage
                    ?
                    <div>
                        <div className="alert alert-danger d-block text-center mt-4">
                            <span>{this.state.errorMessage}</span>
                        </div>

                        <span className="btn btn-block btn-primary"
                            onClick={() => this.props.history.push({ pathname: '/activities' }) } >
                                    Book Another Activity
                         </span>
                    </div>
                    :
                    <div className="mt-2">

                        <div className="row">
                            <div className="col-md-12 py-2 order-md-0">
                                <h3 className="mb-3">Thank you for your booking.</h3>
                                <p>You will receive an email confirmation shortly with your booking information
                                    and instructions for attending this activity.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 order-md-2 mb-4">

                                <h4 className="d-flex justify-content-between align-items-center mb-3">
                                    <span className="text-muted">Booking Details</span>
                                </h4>
                                <ul className="list-group mb-3">
                                    <li className="list-group-item d-flex justify-content-between lh-condensed">
                                        <div>
                                            <h6 className="my-0">Booking ref</h6>
                                            <small className="text-muted">                                                
                                                {this.state.activityBooking.activityBookingId}</small>
                                        </div>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between lh-condensed">
                                        <div>
                                            <h6 className="my-0">Start date:</h6>
                                            <small className="text-muted">
                                                <Moment format="DD/MM/yyyy h:mm A">{this.state.activityBooking.startDate}</Moment>
                                            </small>
                                        </div>
                                    </li>

                                </ul>

                                <h4 className="d-flex justify-content-between align-items-center mb-3">
                                    <span className="text-muted">Charges</span>
                                </h4>
                                <ul className="list-group mb-3">

                                    {this.state.activityBooking.activityCharges.map(record =>
                                        <div key={record.activityChargeId}>
                                        {record.quantity != null &&
                                           <li className="list-group-item d-flex justify-content-between lh-condensed"
                                            >
                                                <div>
                                                    <h6 className="my-0">{record.quantity} x {record.name}</h6>
                                                </div>
                                                <span className="text-muted">&pound;{record.gross}</span>
                                            </li>
                                        }
                                        </div>
                                    )}

                                    <li className="list-group-item d-flex justify-content-between lh-condensed">
                                        <div>
                                            <h6 className="my-0">Total</h6>

                                        </div>
                                        <span className="text-muted">&pound;
                                            {this.state.activityBooking.activityCharges.map(item => item.gross).reduce((prev, curr) => prev + curr, 0)}
                                        </span>
                                    </li>
                                </ul>
                                <ul className="list-group mb-3">
                                    <h4 className="d-flex justify-content-between align-items-center mb-3">
                                        <span className="text-muted">Payments</span>
                                    </h4>
                                    
                                    {this.state.paymentIntent &&
                                        <li className="list-group-item d-flex justify-content-between lh-condensed">
                                            <div>
                                                <h6 className="my-0">
                                                    <Moment format="DD/MM/yyyy">
                                                        {new Date(this.state.paymentIntent.created * 1000)}
                                                    </Moment>

                                                </h6>
                                            </div>
                                            <small>{this.state.paymentIntent.payment_method_types[0]}</small>
                                            <span className="text-muted">&pound;
                                                {this.state.paymentIntent.amount / 100}
                                            </span>
                                        </li>
                                    }
                                    
                                    <li className="list-group-item d-flex justify-content-between lh-condensed">
                                        <div>
                                            <h6 className="my-0">Total Payments</h6>

                                        </div><span className="text-muted">&pound;{this.state.paymentIntent? (this.state.paymentIntent.amount / 100) : 0  }</span>
                                    </li>
                                </ul>

                            </div>
                        </div>

                    </div>
                }

             </div>
         )
    }

    render() {
        
        let contents = this.state.loading
            ?
            <div className="h-400 p-5 d-flex align-items-center justify-content-center">
                <Spinner animation="border" />
            </div>
                
            : this.renderGrid();

        return (
            <div>                
                {contents}
            </div>
            //table 3 rows, 1 column
            
        );

    }

    async  checkStatus(clientSecret) {
 
        
        const key = "pk_live_Uz255eHdHPOfMw4MS66vJ8CP003fN66jv4";
        const stripe = await loadStripe(key);
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
        
        this.setState({ paymentIntent: paymentIntent });

        switch (paymentIntent.status) {
            case "succeeded":
                this.saveBooking();
                break;
            case "processing":
                this.showMessage("Your payment is processing.");
                break;
            case "requires_payment_method":
                this.showMessage("Your payment was not successful, please try again.");
                break;
            default:
                this.showMessage("Something went wrong.");
                break;
        }

        
    }

    async showMessage(msg) {
        this.setState({ errorMessage: msg , loading: false});
    }

    async saveBooking() {

        const sessionId = localStorage.getItem("sessionId");
        const savedSessionId = localStorage.getItem("savedSessionId");

        if (sessionId === savedSessionId) {
            this.setState({ errorMessage: 'A booking with same data has already been saved. Did you reload the page?', loading: false });
            return false;
        }
            

        let bookingModel = JSON.parse(localStorage.getItem("bookingModel"));
        const bookedDetails = JSON.parse(localStorage.getItem("bookedDetails"));
        const appliedVoucher = JSON.parse(localStorage.getItem("appliedVoucher"));
        const nonResidentModel = JSON.parse(localStorage.getItem("nonResidentModel"));
        
        const vouchers = [];
        if (appliedVoucher) {            
            vouchers.push(appliedVoucher);
        }

        if (!bookingModel) 
            bookingModel = nonResidentModel;
        
        const payments = [];
        if (this.state.paymentIntent && this.state.paymentIntent.amount) {
            payments.push({
                amount: this.state.paymentIntent.amount / 100,
                paymentType: "stripe",
                name: bookingModel.firstName + ' ' + bookingModel.surname ,
                address1: bookingModel.address1,
                address2: bookingModel.address2,
                postcode: bookingModel.postcode,
                email: bookingModel.email,
                phone: bookingModel.phone,
                paymentGatewayRef:  this.state.paymentIntent.id ,
                lastDigitsCard: '',
                paymentStatus: 'Confirmed'
            });
        }        

        const payload = {
            accountId: bookingModel.accountId,
            bookingId: bookingModel.bookingId,
            firstName: bookingModel.firstName,
            surname: bookingModel.surname,
            address1: bookingModel.address1,
            address2: bookingModel.address2,
            town: bookingModel.town,
            county: bookingModel.county,
            postcode: bookingModel.postcode,
            emailAddress: bookingModel.email,
            phone: bookingModel.phone,
            activityTypeId: bookedDetails.activityTypeId,
            activitySessionId: bookedDetails.activitySessionId, 
            startDate: bookedDetails.startDate,
            endTime: bookedDetails.endTime,
            charges: bookedDetails.orderedTickets,
            vouchers: vouchers,
            paymentIntentId: this.state.paymentIntent ? this.state.paymentIntent.id : '',
            payments: payments
        };
        
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        };

        const response = await fetch('booking/save-booking', options);
        const data = await response.json();

        if (data.activityBookingId > 0) {
            this.setState({
                loading: false,
                errorMessage: null,
                activityBooking: data,
                appliedVoucherAmount: appliedVoucher || 0
            });

            localStorage.setItem("savedSessionId", sessionId);
            this.clearSessionFlowStates();

        } else {
            this.setState({
                loading: false,
                errorMessage: "An error occurred in the saving of the booking."                
            });
        }

    }

    // for non resident flow
    clearSessionFlowStates() {
        
        localStorage.removeItem("nonResidentModel");
        localStorage.removeItem("bookedDetails"); // ticket, qty, type, startdate endtime        
        localStorage.removeItem("nonResidentValidFrom");
        localStorage.removeItem("nonResidentValidTo");
    }
}
