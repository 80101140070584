import React, {Component} from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Spinner from 'react-bootstrap/Spinner';


import { ChargeDetails } from './ChargeDetails';
import CheckoutForm from "./CheckoutForm";
// Live
const key = "pk_live_Uz255eHdHPOfMw4MS66vJ8CP003fN66jv4";
// Test
//const key = "pk_test_51GvMTOH7k0X0lVXZFf74CAasTlARVMJ2hMKKtk1zQw3OLID4eEIIednDUMY8KiFE0wT8sUTOtWEU8v4smCe7Y5Lr00wd8H51ZC";


const stripePromise = loadStripe(key);

export class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            paymentClientSecret: null,
            bookedDetails: null,
            totalSum: 0,
            voucherAmount: 0,
            balance: 0
        };        
    }
        
    componentDidMount() {

        const bookedDetails = localStorage.getItem("bookedDetails");

        if (!bookedDetails) {
            this.props.history.push('/home');
            return false;
        }        

        const validatedCharges = this.props.history.location.state.validatedCharges;
        
        this.setState({
            bookedDetails:  this.props.history.location.state.bookedDetails,            
            totalSum:       this.props.history.location.state.totalSum,
            voucherAmount:  this.props.history.location.state.voucherAmount,
            balance:        this.props.history.location.state.balance
        });

        this.getIntent(validatedCharges);
    }
  
    renderGrid() {

        const options = {
            // passing the client secret obtained from the server            
            clientSecret: this.state.paymentClientSecret
        };

        return (
            <div className="container">
                

                <div className="row">
                    <div className="col-md-8 py-2 mb-4">

                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm />
                        </Elements>

                    </div>
                    <div className="col-md-4 py-2 order-md-2 mb-4">
                        <ChargeDetails
                            bookedDetails={this.state.bookedDetails}
                            totalSum={this.state.totalSum}
                            voucherAmount={this.state.voucherAmount}
                            balance={this.state.balance}
                        />
                    </div>
                </div>

               
            </div>
         )
    }

    render() {
        
        let contents = this.state.loading
            ?
            <div className="h-400 p-5 d-flex align-items-center justify-content-center">
                <Spinner animation="border" />
            </div>
            : this.renderGrid();

        return (
            <div>                
                {contents}
            </div>
        );

    }

    async getIntent(charges) {

        const items = charges.map(({ activityId, qty }) => ({ activityId, qty }));
        const totalSum = charges.map(item => item.gross).reduce((prev, curr) => prev + curr, 0);
        
        let payload = {
            items: items,            
            gross: totalSum
        };
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        };

        const response = await fetch('payment/create-intent', options);
        const data = await response.json();

        this.setState({ paymentClientSecret: data.clientSecret, loading: false });

    }
    
}
