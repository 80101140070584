import React, { Component } from 'react';
import { Route } from 'react-router';
import { Redirect } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import 'bootstrap/dist/css/bootstrap.min.css'
import './custom.css'
import { ResidentLogin } from "./components/ResidentLogin";
import { BookedActivities } from "./components/BookedActivities";
import { Activities } from "./components/Activities";
import { ActivitySessions } from "./components/ActivitySessions";
import { BookingDetails } from "./components/BookingDetails";
import { ConfirmDetails } from "./components/ConfirmDetails";
import { Payment } from './components/Payment';
import { PaymentSuccess } from './components/PaymentSuccess';

export default class App extends Component {
    static displayName = App.name;

    constructor() {
        super();
        this.state = {
            isUserAuthenticated: null
        };        
    }
    

    componentDidMount() {
        let bookingModel = localStorage.getItem("bookingModel");
        if (bookingModel) {
            this.setState({ isUserAuthenticated: true });
        } else {
            this.setState({ isUserAuthenticated: false });
        }
        
    }

    handleLogoutCallback = () => {        
        this.setState({ isUserAuthenticated: false });
        localStorage.clear();       
    }

    handleLoginCallback = () => {
        this.setState({ isUserAuthenticated: true });
    }

    render() {
        
      return (
          <Layout rootLogoutCallback={this.handleLogoutCallback}              
              loggedIn={this.state.isUserAuthenticated}
          >
            <Route
                exact
                path='/'
                render={() => {
                    return (
                        this.state.isUserAuthenticated ?
                            <Redirect to="/activities" /> :
                            this.state.isUserAuthenticated === null ? 'Loading...' :
                            <Redirect to="/home" /> 
                        )
                }}
            />

            <Route path='/home' component={Home} />
            <Route path='/counter' component={Counter} />
            <Route path='/fetch-data' component={FetchData} />

            <Route path='/resident-login'
              render={(props) => <ResidentLogin rootLoginCallback={this.handleLoginCallback} {...props} />}
            />

            <Route path='/booked-activities' component={BookedActivities} />
            <Route path='/activities' component={Activities} />
            <Route path='/activity-sessions' component={ActivitySessions} />
            <Route path='/booking-details' component={BookingDetails} />
              <Route path='/confirm-details' component={ConfirmDetails} />
              <Route path='/payment' component={Payment} />
              <Route path='/payment-success' component={PaymentSuccess} />
      </Layout>
    );
  }
}
