import React, { Component } from 'react';

export class Home extends Component {

    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    componentDidMount() {
        let bookingModel = JSON.parse(localStorage.getItem("bookingModel"));

        if (bookingModel) {
            this.props.history.push('/booked-activities');            
        } 
    }
        

  render () {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-9">
                    <img className="img-fluid mx-auto d-block mb-3 mt-2" alt="kenegie manor park"
                        src="https://www.kenegie-manor.co.uk/wp-content/themes/wp-kenegie-manor/images/kenegie-logo.png"
                         />

                    <h2 className="text-center mb-3">Kenegie Manor Activities</h2>
                    <div className="panel text-center">
                        
                        <p className="lead">Welcome to the Kenegie Manor on-line swimming pool booking system. To book a swimming slot, Swimming Times for the days swims are released to residents (guests on site) at 8am daily and for Locals (non-residents) at 8.30am daily.
                        </p>
                        <p className="lead">They can ONLY be booked online using this system and not via the office or walk-in. For holiday makers you will be issued a pass code on check in.</p>
                        <a className="btn btn-primary d-block d-lg-inline mx-1 mb-3 mr-2" href="/resident-login">Pool Passcode Booking</a>
                        <span className="btn btn-primary d-block d-lg-inline mx-1 mb-3"                            
                            onClick={() => {
                                this.props.history.push('/activities');                                
                            }}
                            >Visitors & Vouchers Booking
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
